import { render, staticRenderFns } from "./ProductFeaturesBanner.vue?vue&type=template&id=65b08628"
import script from "./ProductFeaturesBanner.vue?vue&type=script&lang=ts"
export * from "./ProductFeaturesBanner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSectionTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiSectionTitle.vue').default,UiVideo: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiVideo.vue').default})
